import { useRef, useState, useEffect } from "react";
import parser from 'bbcode-to-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import DOMPurify from 'dompurify';

import api from '../service/api.ts';

import { parseJSON, numberFormatCurrency, getSearchArticleSQL } from '../util';

import LazyImage from '../hook/lazyimage';
import useFileCache from '../hook/useFileCache';
import useFocusOnMount from '../hook/useFocusOnMount';
import useDebounce from '../hook/useDebounce';

import storageSession from '../util/storage';
import { useGlobalStore } from '../context/global';
import { useQueryProcess } from '../hook/useQueryProcess';

const getArticles = (search, category = '', lang = 'pl', catalog = false) => {
  if (catalog) {
    const catalogArticleData = api.getCatalogArticles({
      nodekeys: [category],
      offset: 0,
      perpage: 5,
      filtr: getSearchArticleSQL(search),
      lang,
      count_disable: true,
    });

    const catalogArticleCount = new Promise((resolve) => {
      resolve({ data: JSON.parse('{"status": 0, "count": 0, "info": "", "dane": "[]"}') });
    });

    // const catalogArticleCount = api.getCatalogArticles({
    //   nodekeys: [category],
    //   offset: 0,
    //   perpage: 5,
    //   filtr: getSearchArticleSQL(search),
    //   lang,
    //   count_only: true,
    // });

    return [catalogArticleData, catalogArticleCount];
  }

  const articleData = api.getArticlesFetch({
    nodekeys: [category],
    offset: 0,
    perpage: 5,
    filtr: getSearchArticleSQL(search),
    lang,
    count_disable: true,
  });

  const articleCount = new Promise((resolve) => {
    resolve({ data: JSON.parse('{"status": 0, "count": 0, "info": "", "dane": "[]"}') });
  });

  // const articleCount = api.getArticlesFetch({
  //   nodekeys: [category],
  //   offset: 0,
  //   perpage: 5,
  //   filtr: getSearchArticleSQL(search),
  //   lang,
  //   count_only: true,
  // });

  return [articleData, articleCount];
};

const searchMode = {
  Normal: 0,
  Simple: 1,
};

const Div = styled.div`
  border: 2px solid transparent;

  &:focus, &:hover {
    outline: none;
    background-color: #fafafa;
    border: 2px solid var(--primary);
    border-radius: 4px;
    // margin: -2px;
  }
`;

const DivSearch = styled.div`
  &:hover, &:focus-within {
    border-color: var(--primary);
    --tw-ring-color: var(--primary);
  }
`

//-------------------------------------------


const SearchInput = function({
  onClose = () => {},
  isMode = 1,
  isOpen = false,
  catalog = false,
  dialog = false,
}) {
  const [globalState, globalDispatch] = useGlobalStore();
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  const { query, queryClient } = useQueryProcess();

  const refSearch = useRef();
  const refSearchList = useRef();
  const refInfo = useRef();

  const [search, setSearch] = useState(globalState.search);
  const [showInfo, setShowInfo] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [mode, setMode] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const [loadArticleImage] = useFileCache();

  const macOS = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  const onCloseModal = () => {
    setShowInfo(false);
    onClose();
    return true;
  };

  const searchCancel = async() => {
    // console.log("search: cancel");
    setCancel(true);
    await queryClient.cancelQueries({ queryKey: ['search_dialog'], exact: false });
  };

  const getFinalSearch = (search) => {
    const preSearch = search || refSearch.current.value;
    // const regSearch = /([%_])+/g;
    // const finalSearch = preSearch.replace(regSearch, '').trim();
    const finalSearch = preSearch.trim();
    // console.log("FINAL SEARCH", finalSearch);
    return finalSearch;
  }

  const searchClick = (type = -1) => {
    setShowInfo(false);
    searchCancel();
    onCloseModal();

    const searchType = isMode != searchMode.Normal ? 0 : type;
    searchType != -1 && changeSearchType(undefined, searchType);
    globalDispatch.setSearch(getFinalSearch());

    if (document.location.pathname.match(/(\/home|\/article|\/cart)/g)?.length > 0) {
      const category = globalState.searchType != 0 && globalState.category.length > 0 ? "#" + globalState.category : "";
      navigate(`/article${category}`);
    } else if (document.location.pathname.match(/(\/catalog)/g)?.length > 0) {
      const category = globalState.catalogSearchType != 0 && globalState.category.length > 0 ? "#" + globalState.category : "";
      navigate(`/catalog${category}`);
    }
  };

  const searchClear = () => {
    refSearch.current.value = '';
    globalDispatch.setSearch('');
    setCancel(false);
    setSearch('');
    setShowInfo(false);
  };

  const changeSearchType = (e, type) => {
    if (e != undefined) {
      e.preventDefault();
      e.stopPropagation();
    }

    globalDispatch.setSearchType(type === 0 ? 0 : 1);    
    if (globalState.search != '' && type == 0) {
      globalDispatch.setCategory('');
    }
    setMode(false);
  };

  const fetchSearch = async (_search, _signal) => {
    setShowInfo(false);
    searchCancel();

    if (_search == '') return false;

    const categoryCurr = globalState.searchType == 0 || isMode != searchMode.Normal ? '' : globalState.category;
    const langTarget = storageSession.get("global-lang", "pl");
    const articleFetch = getArticles(_search, categoryCurr, langTarget, catalog);

    _signal?.addEventListener('abort', () => {
      articleFetch[0]?.cancel('Search data was cancelled!');
    });

    const articleData = await Promise.all(articleFetch);

    const responseData = articleData[0]?.data;
    const responseCount = articleData[1]?.data;
    console.log(responseCount, articleData[1]);
    if (responseData?.status != 0 || responseCount?.status != 0)
      return false;

    const data = parseJSON(responseData?.dane);
    setShowInfo(true);

    if (data === false || data === '' || data.length === 0)
      return { list: [], max: 0 };

    // return { list: data, max: +responseData?.count };
    return { list: data, max: +data.length };
  };

  const searchKeyDown = (e) => {
    if (e.key === 'ArrowDown' && searchList.length > 0 && refSearchList.current != undefined) {
      // console.log(refSearchList.current.children);
      if (refSearchList.current.children[0] != undefined)
        refSearchList.current.children[0].children[0].focus();
      return;
    }

    const finalSearch = getFinalSearch(e.target.value);
    if (e.key === 'Enter' && finalSearch.length > 0) {
      // setSearch('');
      setTimeout(() => searchClick(-1), 0);
      return;
    }

    if (finalSearch == search) return;

    setShowInfo(false);
    setCancel(false);
    setSearch(finalSearch);
  };

  const searchListDown = (e) => {
    e.preventDefault();

    if (e.key == "ArrowDown") {
      e.currentTarget.nextSibling?.focus();
    }

    if (e.key == "ArrowUp") {
      if (e.currentTarget.tabIndex == 0) {
        refSearch.current?.focus();
        return;
      }

      e.currentTarget.previousSibling.focus();
    }

    if (e.key == "Enter") {
      const symbol = e.currentTarget.dataset.symbol;
      const link = `${!catalog ? '/article' : '/catalog'}/show/${encodeURIComponent(symbol)}`; 

      navigate(link);
      onCloseModal();
    }
  }

  const displayInfo = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.value == "") return;

    setShowInfo(true);
  };

  const hiddenInfo = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (refInfo.current && refSearch.current &&
        !refInfo.current.contains(document.activeElement) &&
        !refSearch.current.contains(document.activeElement)
      ) {
        setShowInfo(false);
      }
    }, 100);
  };

  useEffect(() => {
    // if (debouncedSearch == globalState.search) return;
    setShowInfo(true);
  }, [debouncedSearch]);

  useEffect(() => {
    console.log("SEARCH_____", globalState.search);
    if (refSearch.current) refSearch.current.value = globalState.search;
  }, [globalState.search]);

  useFocusOnMount(refSearch, 100);
  useEffect(() => {
    setTimeout(() => {
      if (refSearch.current == undefined || isOpen == false) return;
      refSearch.current.focus();
      // refSearch.current.click();
      // let event = new KeyboardEvent('touchstart', {'bubbles':true});            
      // refSearch.current.dispatchEvent(event);
    }, 100);
  }, [refSearch.current, isOpen]);

  const { data: dataList, isLoading, isFetching, isError } = query(
    ["search_dialog", isMode, debouncedSearch, globalState.searchType, globalState.catalogSearchType],
    ({ signal }) => fetchSearch(debouncedSearch, signal),
    {
      placeholderData: { list: [], max: -1 },
      staleTime: 2 * 60 * 1000, // 2 minutes
      // cacheTime: 20 * 60 * 1000,
      keepPreviousData: false,
      enabled: !cancel && debouncedSearch != "",
      // enabled: debouncedSearch != "",
    }
  );

  const { list: searchList, max: maxItems } = dataList || { list: [], max: -1 };

  return (
    <div className={`${dialog ? "p-4 px-3 pb-2.5" : ""} w-full flex flex-col rounded-md`}>
      <div className={`relative flex flex-grow items-center`}>
        <div className={`w-full flex flex-col rounded-md`}>
          {/* SEARCH INPUT */}
          <DivSearch
            className={`bg-white shadow-sm border ring-1 ring-transparent border-1 rounded-md ${globalState.search.length > 0 ? 'border-primary' : 'border-gray-300'} 
              ${dialog ? "-top-1 left-0 mr-12" : "w-full md:min-w-200 lg:min-w-300 xl:min-w-400"}
              relative flex flex-grow items-center`}
          >
            {/* SEARCH MODE */}
            {isMode == searchMode.Normal && (
              <div className="relative rounded-md">
                <button
                  type="button"
                  // ref={refSelect}
                  onClick={() => setMode(!mode)}
                  onBlur={() => setMode(false)}
                  className={`inline-flex justify-center px-3 py-2 pr-8 text-sm font-normal leading-5 text-gray-700 
                      border-gray-200 ml-px border-r bg-white group hover:text-primary focus:outline-none 
                      active:bg-gray-50 active:text-gray-800 whitespace-nowrap`}
                >
                  
                  {!catalog && (globalState.searchType != 1) && (<p>{t('wszystkie\u00A0kategorie')}</p>)}
                  {!catalog && globalState.searchType == 1 && (<p>{t('bieżąca\u00A0kategoria')}</p>)}
                  {catalog && globalState.catalogSearchType != 1 && (<p>{t('wszystkie\u00A0kategorie')}</p>)}
                  {catalog && globalState.catalogSearchType == 1 && (<p>{t('bieżąca\u00A0kategoria')}</p>)}
                  <span className="absolute inset-y-0 right-3 flex items-center z-1">
                    {/* <svg className="w-5 h-5 text-gray-400" viewBox="1 0 20 20" fill="currentColor">
                      <path d="m6.293,7.90276a1,1 0 0 1 1.414,0l2.293,2.293l2.293,-2.293a1,1 0 0 1 1.414,1.414l-3,3a1,1 0 0 1 -1.414,0l-3,-3a1,1 0 0 1 0,-1.414z" />
                    </svg> */}
                    <svg className="w-3 h-3 text-gray-600 group-hover:text-primary" viewBox="-3 -8 38 38" fill="currentColor">
                      <path stroke="4" d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0 
                        l11.095,11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z" 
                      />
                    </svg>
                  </span>
                </button>
                <div className={`absolute right-0 z-20 w-full mt-2 origin-top-right rounded-md shadow-lg transition ease-out duration-100 ${mode ? "opacity-100 visible" : "opacity-0 hidden"}`}>
                  <div className="py-1 overflow-auto text-sm bg-white rounded-md shadow-xs ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <div className="flex flex-col py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <button
                        type="button"
                        onMouseDown={(e) => {
                          changeSearchType(e, 0);
                          searchClear();
                        }}
                        className="px-4 py-2 text-sm leading-5 text-left text-gray-700 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                      >{t('wszystkie\u00A0kategorie')}</button>
                      <button
                        type="button"
                        onMouseDown={(e) => {
                          changeSearchType(e, 1);
                          searchClear();
                        }}
                        className="px-4 py-2 text-sm leading-5 text-left text-gray-700 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                      >{t('bieżąca\u00A0kategoria')}</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <input
              ref={refSearch}
              onKeyUp={searchKeyDown}
              onFocus={displayInfo}
              onBlur={hiddenInfo}
              // autoComplete="off"
              className={`w-full h-10 px-4 pl-3 pr-16 text-sm rounded-md focus:outline-none 
                border-none active:border-none active:ring-none focus:border-none focus:ring-none`}
              type="search"
              name="search"
              placeholder={t('Szukaj...')+`${dialog ? "" : ` (${macOS ? '⌘' : 'Ctrl+'}K)`}`}
              data-testid="gorna_belka-szukaj"
              defaultValue={globalState.search}
            />
            <button
              className={`${globalState.search.length > 0 ? '' : 'hidden'} text-gray-500 absolute top-2 right-10 hover:cursor-pointer group`}
              onClick={searchClear}
              title="wyczyść"
              // data-title="wyczyść"
              // data-placement="left"
            >
              <svg className="w-6 h-6 text-blue-400 group-hover:text-red-500" viewBox="0 0 48 48" fill="white">
                <g>
                  <path d="M44.7818 24.1702L31.918 7.09938L14.1348 20.5L27.5 37L30.8556 34.6644L44.7818 24.1702Z" fill="currentColor" stroke="#555555" strokeWidth="3.5" strokeLinejoin="round"/>
                  <path d="M27.4998 37L23.6613 40.0748L13.0978 40.074L10.4973 36.6231L4.06543 28.0876L14.4998 20.2248" stroke="#555555" strokeWidth="4" strokeLinejoin="round"/>
                  <path d="M13.2056 40.0721L44.5653 40.072" stroke="#555555" strokeWidth="4" strokeLinecap="round"/>
                </g>
              </svg>
              {/* <svg className="w-6 h-6" viewBox="-260 -200 800 800" fill="currentColor">
                <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 
                  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 
                  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 
                  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"
                />
              </svg> */}
            </button>
            <button 
              className={`${isLoading || isFetching ? '' : 'hidden'} text-gray-500 bg-white absolute top-2.5 right-10 mt-px hover:cursor-pointer`}
            >
              <svg className='w-10 h-5' viewBox="0 0 20 20" preserveAspectRatio='xMinYMid'>
                <g transform="translate(10 10)">
                  <circle cx="0" cy="0" r="4" fill="var(--primary)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                  </circle>
                </g>
                <g transform="translate(22 10)">
                  <circle cx="0" cy="0" r="4" fill="var(--primary)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                  </circle>
                </g>
                  <g transform="translate(34 10)">
                  <circle cx="0" cy="0" r="4" fill="var(--primary)">
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                  </circle>
                </g>
              </svg>
            </button>

            <button
              type="submit"
              className={`absolute focus:outline-none hover:text-primary group top-3 right-3`}
              onClick={() => searchClick(-1)}
              data-cy="search-button"
            >
              <svg className="w-4 h-4 text-gray-600 group-hover:text-primary" viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 58 58'}} fill="currentColor">
                <g transform="scale(1,1)">
                  <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  
                  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  
                  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  
                  s-17-7.626-17-17S14.61,6,23.984,6z"
                />
                </g>
              </svg>
            </button>
          </DivSearch>

          {/* <div className="relative -left-5">{showInfo? "TAK": "NIE"}</div> */}

          <div
            className={`${!dialog ? "absolute min-w-full top-12 bg-white shadow-md z-10 border border-gray-300 p-3 pt-2 rounded-md" : ""} 
              ${dialog || ((maxItems == 0 || isError || searchList.length > 0) && showInfo) ? "" : "hidden"} 
            `}
            ref={refInfo}
            tabIndex={-1}
            onBlur={hiddenInfo}
          >
            {/* SEARCH ALERT INFO */}
            <div className={`relative ${(maxItems == 0 || isError) ? '' : 'hidden'}`} id="search-empty">
              <div className="mt-2 relative py-2 bg-red-400 border border-gray-100 rounded shadow rounded-t-0">
                <div className="p-2 text-sm text-center text-white">
                  {t('Brak listy artykułów o podanym wyrażeniu wyszukiwania')}
                </div>
              </div>
            </div>

            {/* SEARCH AUTOCOMPLETE LIST */}
            <div className={`relative ${searchList.length > 0 ? '' : 'hidden'}`} id="search-list" ref={refSearchList}>
              {/* {searchList.length > 0 && (
                <div className="my-1 text-sm">{t('Razem')}: <span className="font-semibold">{maxItems}</span></div>
              )} */}
              <div className={`relative w-full py-2 pt-1 bg-white`}>
                {searchList.length > 0 && searchList?.map((item, index) => (
                  <Div
                    className="flex flex-row p-2 text-sm cursor-pointer"
                    key={"search-result_"+ Math.random()*100}
                    tabIndex={index}
                    onKeyDown={searchListDown}
                    data-symbol={encodeURIComponent(item.symbol_art)}
                  >
                    <div className="w-10 mr-2 min-w-10">
                      <LazyImage
                        className="w-10 h-10"
                        src=""
                        symbol={item.symbol_art}
                        width="40"
                        height="40"
                        alt=""
                        handlerLoad={(symbol) => loadArticleImage(symbol, 40)}
                      />
                    </div>
                    <div className="flex min-w-0">
                      <Link 
                        to={`${!catalog ? '/article' : '/catalog'}/show/${encodeURIComponent(item.symbol_art)}`} 
                        className="overflow-hidden"
                        onClick={onCloseModal}
                        reloadDocument={false}
                      >
                        <span className="break-words">
                          {parser.toReact(item.nazwa_art)}
                        </span>
                        <span className="block text-gray-400">
                          {!catalog ?
                            <>
                              <span className="pr-4">{numberFormatCurrency(item.cena, item.currency)}&nbsp;/&nbsp;{item.symbol_jm}</span>
                              <span translate='no'>{item.label != null && item.label?.length > 0 ? `(${item.label})` : ''}</span>
                            </>
                          :
                            <>
                              {item.cena_n != undefined && <span className="pr-4">{numberFormatCurrency(item.cena_n, item.currency)}&nbsp;/&nbsp;{item.symbol_jm}</span>}
                              <span translate='no'>{item.symbol_art != null && item.symbol_art?.length > 0 ? `(${item.symbol_art})` : ''}</span>
                            </>
                          }
                        </span>
                      </Link>
                    </div>
                  </Div>
                ))}
                {searchList.length > 0 && maxItems >= 5 && (
                  <div className="flex flex-row p-2 pb-0">
                    <button
                      className="w-full p-2 px-2 text-sm font-normal border border-gray-300 rounded text-primary border-1 focus:outline-none hover:border-gray-800 hover:text-gray-800"
                      onClick={() => searchClick(0)}
                    >
                      {t('Zobacz wszystkie')}
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* FOOTER INFO */}
            <footer className={`${searchList.length > 0 ? "hidden xs:block": "hidden"} mt-1.5 pt-2 border-t border-gray-200`}>
              <div data-testid="searchKeyboardHits">
                <ul className="flex flex-row justify-center gap-x-3 text-gray-400">
                  <li className="flex flex-row items-center">
                    <div className="bg-blue-200/25 p-1 rounded-md">
                      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-label="Enter key" role="img">
                        <polyline points="9 10 4 15 9 20" />
                        <path d="M20 4v7a4 4 0 0 1-4 4H4" />
                      </svg>
                    </div>
                    <span className="text-sm ml-2" data-title="to select" data-placement="bottom">{t('aby zatwierdzić')}</span>
                  </li>
                  <li className="flex flex-row items-center gap-x-0.5">
                    <div className="bg-blue-200/25 p-1 rounded-md">
                      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-label="Arrow down" role="img">
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <polyline points="19 12 12 19 5 12" />
                      </svg>
                    </div>
                    <div className="bg-blue-200/25 p-1 rounded-md">
                      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-label="Arrow up" role="img">
                        <line x1="12" y1="19" x2="12" y2="5" />
                        <polyline points="5 12 12 5 19 12" />
                      </svg>
                    </div>
                    <span className="text-sm ml-2" data-title="to navigate" data-placement="bottom">{t('aby przejść')}</span>
                  </li>
                  {dialog && (
                    <li className="flex flex-row items-center">
                      <div className="bg-blue-200/25 p-1 py-2 rounded-md">
                        <svg className="relative top-[1px] w-6 h-2" viewBox="0 0 16 6" aria-label="Escape key" role="img" fill="currentColor">
                          <path d="M2.76778 5.09587C2.27559 5.09587 1.85052 4.9936 1.49256 4.78905C1.13674 4.58238 0.862948 4.29047 0.671187 3.91334C0.479426 
                            3.53408 0.383545 3.0877 0.383545 2.57421C0.383545 2.06924 0.479426 1.62605 0.671187 1.24466C0.865079 0.861139 1.13568 0.562844 1.48298 
                            0.349776C1.83028 0.134577 2.2383 0.0269775 2.70705 0.0269775C3.00961 0.0269775 3.29512 0.0759834 3.56359 0.173995C3.83418 0.269875 
                            4.07282 0.419023 4.2795 0.621438C4.4883 0.823853 4.65237 1.08167 4.77168 1.39488C4.891 1.70596 4.95066 2.07669 4.95066 
                            2.50709V2.86185H0.926869V2.08202H3.84164C3.83951 1.86043 3.79157 1.66334 3.69782 1.49076C3.60407 1.31604 3.47303 1.17861 
                            3.30471 1.07847C3.13852 0.978327 2.94462 0.928256 2.72303 0.928256C2.48653 0.928256 2.27879 0.985784 2.09981 1.10084C1.92083 
                            1.21377 1.78127 1.36291 1.68113 1.54828C1.58312 1.73152 1.53305 1.93287 1.53092 2.15233V2.83309C1.53092 3.1186 1.58312 3.36363 
                            1.68752 3.56817C1.79193 3.77059 1.93788 3.92613 2.12538 4.03479C2.31288 4.14132 2.53234 4.19459 2.78376 4.19459C2.95208 4.19459 
                            3.10443 4.17115 3.24079 4.12428C3.37715 4.07527 3.49541 4.0039 3.59555 3.91015C3.69569 3.8164 3.77133 3.70027 3.82247 3.56178L4.90272 
                            3.68323C4.83454 3.96874 4.70457 4.21803 4.51281 4.4311C4.32318 4.64203 4.08028 4.8061 3.78411 4.92328C3.48795 5.03834 3.14917 
                            5.09587 2.76778 5.09587Z"
                          />
                          <path d="M9.80941 1.38848L8.75473 1.50354C8.7249 1.39701 8.6727 1.29686 
                            8.59812 1.20311C8.52568 1.10936 8.42767 1.03372 8.30409 0.976196C8.18051 0.918668 8.02923 0.889904 7.85025 0.889904C7.60949 0.889904 
                            7.40707 0.942105 7.24301 1.04651C7.08108 1.15091 7.00118 1.28621 7.00331 1.4524C7.00118 1.59516 7.05338 1.71128 7.15991 
                            1.80077C7.26858 1.89026 7.44755 1.96377 7.69684 2.0213L8.5342 2.20027C8.99869 2.30042 9.34386 2.45915 9.56971 2.67648C9.7977 
                            2.89381 9.91275 3.17826 9.91488 3.52982C9.91275 3.83877 9.8222 4.11149 9.64322 4.348C9.46638 4.58238 9.22028 4.76561 8.90494 
                            4.89772C8.5896 5.02982 8.22738 5.09587 7.81829 5.09587C7.21744 5.09587 6.73378 4.97016 6.3673 4.71874C6.00082 4.46519 5.78243 
                            4.11256 5.71211 3.66086L6.84031 3.55219C6.89145 3.77378 7.00011 3.94104 7.1663 4.05397C7.3325 4.16689 7.54876 4.22336 7.8151 
                            4.22336C8.08995 4.22336 8.31048 4.16689 8.47667 4.05397C8.645 3.94104 8.72916 3.80148 8.72916 3.63529C8.72916 3.49466 8.67483 
                            3.37854 8.56616 3.28692C8.45963 3.1953 8.29344 3.12499 8.06758 3.07598L7.23023 2.9002C6.75934 2.80219 6.41098 2.63706 6.18513 
                            2.40482C5.95927 2.17044 5.84741 1.87428 5.84954 1.51632C5.84741 1.21377 5.92944 0.951694 6.09564 0.730103C6.26396 0.506381 
                            6.49727 0.333796 6.79557 0.212347C7.09599 0.0887672 7.44223 0.0269775 7.83427 0.0269775C8.40956 0.0269775 8.86233 0.149492 
                            9.19258 0.39452C9.52497 0.639549 9.73058 0.97087 9.80941 1.38848Z" 
                            />
                          <path d="M13.0102 5.09587C12.5202 5.09587 12.0994 4.98827 11.7478 4.77307C11.3984 4.55787 11.1288 4.26064 10.9392 
                            3.88138C10.7517 3.49999 10.658 3.06107 10.658 2.56462C10.658 2.06604 10.7538 1.62605 10.9456 1.24466C11.1374 0.861139 
                            11.408 0.562844 11.7574 0.349776C12.109 0.134577 12.5244 0.0269775 13.0038 0.0269775C13.4023 0.0269775 13.7549 0.100486 
                            14.0617 0.247503C14.3707 0.392389 14.6168 0.598 14.8 0.864336C14.9832 1.12854 15.0876 1.43749 15.1132 1.79118H14.0074C13.9626 
                            1.55468 13.8561 1.35759 13.6878 1.19992C13.5216 1.04012 13.2989 0.960216 13.0198 0.960216C12.7833 0.960216 12.5756 1.02414 
                            12.3966 1.15198C12.2176 1.27769 12.0781 1.4588 11.9779 1.6953C11.8799 1.93181 11.8309 2.21519 11.8309 2.54544C11.8309 
                            2.87996 11.8799 3.1676 11.9779 3.40837C12.0759 3.64701 12.2134 3.83131 12.3902 3.96128C12.5692 4.08912 12.7791 4.15304 
                            13.0198 4.15304C13.1903 4.15304 13.3426 4.12108 13.4769 4.05716C13.6132 3.99111 13.7272 3.8963 13.8188 3.77272C13.9104 
                            3.64914 13.9733 3.49892 14.0074 3.32208H15.1132C15.0855 3.66938 14.9832 3.97726 14.8064 4.24573C14.6295 4.51206 14.3888 
                            4.72087 14.0841 4.87215C13.7794 5.0213 13.4215 5.09587 13.0102 5.09587Z" 
                          />
                        </svg>
                      </div>
                      <span className="text-sm ml-2" data-title="to close" data-placement="bottom">{t('aby zamknąć')}</span>
                    </li>
                  )}
                </ul>
              </div>
              {/* <div>
                <span class="">Powered by </span>
                <a href={void(0)} target="_blank" rel="noreferrer" aria-label="Humansoft">
                  <img src="" alt="Powered by Humansoft" />
                </a>
              </div> */}
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
